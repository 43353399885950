define("ember-gestures/templates/components/fast-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LN5LEmza",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\"]}",
    "meta": {
      "moduleName": "ember-gestures/templates/components/fast-action.hbs"
    }
  });

  _exports.default = _default;
});