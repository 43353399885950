define("ember-maybe-in-element/components/maybe-in-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @renderInPlace}}{{yield}}{{else}}{{#in-element @destinationElement insertBefore=null}}{{yield}}{{/in-element}}{{/if}}
  */
  {
    "id": "DCPjnm7R",
    "block": "{\"symbols\":[\"&default\",\"@destinationElement\",\"@renderInPlace\"],\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[18,1,null]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[18,1,null]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-in-el-null\",\"in-element\",\"if\"]}",
    "meta": {
      "moduleName": "ember-maybe-in-element/components/maybe-in-element.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});