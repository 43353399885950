define("ember-table/components/ember-thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#each this.wrappedRows as |api|}}
    {{#if (has-block)}}
      {{yield (hash
        cells=api.cells
        isHeader=api.isHeader
        rowsCount=api.rowsCount
  
        row=(component "ember-tr" api=api)
      )}}
    {{else}}
      <EmberTr @api={{api}} />
    {{/if}}
  {{/each}}
  
  */
  {
    "id": "ztKAayar",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"wrappedRows\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[30,[36,1],null,[[\"cells\",\"isHeader\",\"rowsCount\",\"row\"],[[32,1,[\"cells\"]],[32,1,[\"isHeader\"]],[32,1,[\"rowsCount\"]],[30,[36,0],[\"ember-tr\"],[[\"api\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"ember-tr\",[],[[\"@api\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-table/components/ember-thead/template.hbs"
    }
  });

  _exports.default = _default;
});