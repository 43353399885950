define("ember-table/components/ember-th/sort-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.isSorted}}
    <span data-test-sort-indicator class="et-sort-indicator {{if this.isSortedAsc 'is-ascending' 'is-descending'}}">
      {{#if (has-block)}}
        {{yield this.columnMeta}}
      {{else}}
        {{#if this.isMultiSorted}}
          {{this.sortIndex}}
        {{/if}}
      {{/if}}
    </span>
  {{/if}}
  
  {{#if this.isSortable}}
    <button data-test-sort-toggle class="et-sort-toggle et-speech-only">Toggle Sort</button>
  {{/if}}
  
  */
  {
    "id": "Qx447XTG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isSorted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,\"data-test-sort-indicator\",\"\"],[15,0,[31,[\"et-sort-indicator \",[30,[36,0],[[32,0,[\"isSortedAsc\"]],\"is-ascending\",\"is-descending\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[32,0,[\"columnMeta\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isMultiSorted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,0,[\"sortIndex\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isSortable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,\"data-test-sort-toggle\",\"\"],[14,0,\"et-sort-toggle et-speech-only\"],[12],[2,\"Toggle Sort\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-table/components/ember-th/sort-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});