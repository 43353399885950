define("ember-bootstrap/utils/cp/type-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = typeClass;

  function typeClass(prefix, typeProperty) {
    (true && !(typeof prefix === 'string') && Ember.assert('You have to provide a prefix for typeClass', typeof prefix === 'string'));
    (true && !(typeof typeProperty === 'string') && Ember.assert('You have to provide a typeProperty for typeClass', typeof typeProperty === 'string'));
    return Ember.computed('outline', 'type', function () {
      var type = this.get(typeProperty) || 'default';
      (true && !(typeof type === 'string' && type !== '') && Ember.assert('The value of `type` must be a string', typeof type === 'string' && type !== ''));

      if (this.outline) {
        return "".concat(prefix, "-outline-").concat(type);
      }

      return "".concat(prefix, "-").concat(type);
    });
  }
});