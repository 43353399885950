define("ember-concurrency-decorators/index", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/typeof", "@ember-decorators/utils/decorator", "ember-concurrency", "ember-concurrency-decorators/last-value"], function (_exports, _objectSpread2, _slicedToArray2, _typeof2, _decorator, _emberConcurrency, _lastValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keepLatestTaskGroup = _exports.keepLatestTask = _exports.enqueueTaskGroup = _exports.enqueueTask = _exports.dropTaskGroup = _exports.dropTask = void 0;
  Object.defineProperty(_exports, "lastValue", {
    enumerable: true,
    get: function get() {
      return _lastValue.default;
    }
  });
  _exports.taskGroup = _exports.task = _exports.restartableTaskGroup = _exports.restartableTask = void 0;

  /**
   * This utility function assures compatibility with the Ember object model style
   * and initializer syntax required by Babel 6.
   *
   * For native classes using the method shorthand style (TypeScript & Babel 7),
   * this function will access the `value`. For legacy code it will get the value
   * from the initializer.
   *
   * // Ember object model
   * export default EmberObject.extend({
   *   @task
   *   someTask: function*() {}
   * });
   *
   * // Class syntax with initializers
   * export default class extends EmberObject {
   *   @task
   *   someTask = function*() {}
   * }
   *
   * @param desc
   * @returns {object|null}
   * @private
   */
  function extractValue(desc) {
    if (typeof desc.initializer === 'function') {
      return desc.initializer.call(undefined);
    }

    if (typeof desc.get === 'function') {
      return desc.get.call(undefined);
    }

    if (desc.value) {
      return desc.value;
    }

    return undefined;
  }

  function isTaskFunction(value) {
    return typeof value === 'function';
  }

  function isEncapsulatedTask(value) {
    return (0, _typeof2.default)(value) === 'object' && value !== null && isTaskFunction(value.perform);
  }
  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskProperty`.
   *
   * @param desc
   * @returns {TaskProperty}
   * @private
   */


  function createTaskFromDescriptor(desc) {
    var value = extractValue(desc);

    if (isTaskFunction(value)) {
      return (0, _emberConcurrency.task)(value);
    }

    if (isEncapsulatedTask(value)) {
      return (0, _emberConcurrency.task)(value);
    }

    (true && !(false) && Ember.assert('ember-concurrency-decorators: Can only decorate a generator function as a task or an object with a generator method `perform` as an encapsulated task.'));
  }
  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskGroupProperty`.
   *
   * @param desc
   * @returns {TaskGroupProperty}
   * @private
   */


  function createTaskGroupFromDescriptor(_desc) {
    return (0, _emberConcurrency.taskGroup)();
  }
  /**
   * Applies the `options` provided using the chaining API on the given `task`.
   *
   * @param options
   * @param {TaskProperty|TaskGroupProperty} task
   * @private
   */


  function applyOptions(options, task) {
    var keys = Object.keys(options);

    for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
      var key = _keys[_i];
      var value = options[key];
      (true && !(value !== undefined) && Ember.assert("ember-concurrency-decorators: Option '".concat(key, "' is not a valid value"), value !== undefined));
      (true && !(typeof task[key] === 'function') && Ember.assert("ember-concurrency-decorators: Option '".concat(key, "' is not a valid function"), typeof task[key] === 'function'));

      if (value === true) {
        task[key]();
      } else {
        task[key](value);
      }
    } // The CP decorator gets executed in `createDecorator`


    return task;
  }

  function createDecorator(propertyCreator) {
    var baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _decorator.decoratorWithParams)(function (target, key, desc) {
      var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
          _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          userOptions = _ref2[0];

      var initializer = desc.initializer,
          value = desc.value;
      delete desc.initializer;
      delete desc.value;
      return applyOptions((0, _objectSpread2.default)((0, _objectSpread2.default)({}, baseOptions), userOptions), propertyCreator((0, _objectSpread2.default)((0, _objectSpread2.default)({}, desc), {}, {
        initializer: initializer,
        value: value
      })))(target, key, desc);
    });
  }
  /**
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @task
   *   *plainTask() {}
   *
   *   @task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */


  var task = createDecorator(createTaskFromDescriptor);
  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.task = task;
  var restartableTask = createDecorator(createTaskFromDescriptor, {
    restartable: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.restartableTask = restartableTask;
  var dropTask = createDecorator(createTaskFromDescriptor, {
    drop: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.dropTask = dropTask;
  var keepLatestTask = createDecorator(createTaskFromDescriptor, {
    keepLatest: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.keepLatestTask = keepLatestTask;
  var enqueueTask = createDecorator(createTaskFromDescriptor, {
    enqueue: true
  });
  /**
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task taskGroup } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @taskGroup({ maxConcurrency: 5 }) someTaskGroup;
   *
   *   @task({ group: 'someTaskGroup' })
   *   *someTask() {}
   *
   *   @task({ group: 'someTaskGroup' })
   *   *anotherTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.enqueueTask = enqueueTask;
  var taskGroup = createDecorator(createTaskGroupFromDescriptor);
  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.taskGroup = taskGroup;
  var restartableTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    restartable: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.restartableTaskGroup = restartableTaskGroup;
  var dropTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    drop: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.dropTaskGroup = dropTaskGroup;
  var keepLatestTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    keepLatest: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.keepLatestTaskGroup = keepLatestTaskGroup;
  var enqueueTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    enqueue: true
  });
  _exports.enqueueTaskGroup = enqueueTaskGroup;
});