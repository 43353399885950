define("ember-gridstack/modifiers/gs-item-attributes", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-modifier"], function (_exports, _slicedToArray2, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2021, Yahoo Inc.
   * Copyrights licensed under the BSD License. See the accompanying LICENSE file for terms.
   */
  var GS_PREFIX = 'gs-';

  var _default = (0, _emberModifier.modifier)(function itemAttributes(element, opt) {
    var _ref = opt || [],
        _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        _ref2$ = _ref2[0],
        options = _ref2$ === void 0 ? {} : _ref2$;

    Object.entries(options).forEach(function (_ref3) {
      var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
          key = _ref4[0],
          value = _ref4[1];

      var attribute = "".concat(GS_PREFIX).concat(Ember.String.dasherize(key));

      if (Ember.isBlank(value)) {
        element.removeAttribute(attribute);
      } else {
        element.setAttribute(attribute, value);
      }
    });
  });

  _exports.default = _default;
});